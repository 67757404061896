import React, { useContext, useState } from "react";
import { GameContext } from "../context/GameContext";
import {
  quickStartMode,
  teamsMode,
  diceStage,
  waiting,
  isFromPWA,
} from "../utils/GlobalVariables";
import GameLogo from "../components/GameLogo";
import useForceUpdate from "../hooks/useForceUpdate";
import { redirectIfMust } from "../utils/utils";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { btnVariants } from "../utils/animationUtils";
import PageContainer from "../components/PageContainer";

const HomePage = () => {
  const [game, setGame] = useContext(GameContext);
  const forceUpdate = useForceUpdate();

  const quickStartBtnStyle = { backgroundColor: "#000", marginLeft: -80 };
  const setTeamsBtnStyle = {
    backgroundColor: "#000",
    marginLeft: 80,
    marginTop: -20,
  };
  const rulesBtnStyle = { marginLeft: -80, marginTop: -10 };

  const handleGameStart = () => {
    setGame((prev) => {
      prev.mode = quickStartMode;
      prev.state = prev.useDice ? diceStage : waiting;
      return prev;
    });
    forceUpdate();
  };

  const handleTeamsMode = () => {
    setGame((prev) => {
      prev.mode = teamsMode;
      return prev;
    });
    forceUpdate();
  };

  const handleQuickStart = () => {
    setGame((prev) => {
      prev.mode = quickStartMode;
      return prev;
    });
    forceUpdate();
  };

  return (
    <PageContainer classTitle="home-page-wrapper">
      {redirectIfMust(game)}
      <GameLogo padding />

      <h3 className="home-heading">Half a Minute</h3>
      <h3 className="home-description">A fun time with your faves</h3>

      <motion.button
        variants={btnVariants()}
        onClick={handleQuickStart}
        className="quick-start-btn home-btn"
        style={quickStartBtnStyle}
      >
        QUICK START
      </motion.button>

      {isFromPWA && (
        <svg
          onClick={() => window.open("/?from=pwa", "_self")}
          xmlns="http://www.w3.org/2000/svg"
          width="35.955"
          className="home-reload-btn"
          height="36"
          viewBox="0 0 35.955 36"
        >
          <path
            id="Icon_open-reload"
            data-name="Icon open-reload"
            d="M18,0A18,18,0,1,0,30.78,30.78l-3.24-3.24A13.51,13.51,0,1,1,17.955,4.5a13.091,13.091,0,0,1,9.4,4.095L22.455,13.5h13.5V0L30.6,5.355A17.9,17.9,0,0,0,17.955,0Z"
          />
        </svg>
      )}

      <motion.button
        variants={btnVariants(0.3)}
        onClick={() => handleTeamsMode()}
        style={setTeamsBtnStyle}
        className="set-teams-btn home-btn"
      >
        SET TEAMS
      </motion.button>

      <Link to="/rules">
        <motion.div
          style={rulesBtnStyle}
          variants={btnVariants(0.6)}
          className="home-game-rules-btn"
        >
          RULES
        </motion.div>
      </Link>
    </PageContainer>
  );
};

export default HomePage;
