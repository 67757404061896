import React from "react";
import { doNothingVariants } from "../utils/animationUtils";
import { motion, AnimatePresence } from "framer-motion";

const PageContainer = ({ classTitle, children }) => {
  return (
    <AnimatePresence>
      <motion.div
        variants={doNothingVariants}
        initial="init"
        animate="anim"
        exit="exit"
        className={classTitle}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default PageContainer;
