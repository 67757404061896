import React, { createContext, useState, useEffect, version } from "react";
import { Game, cards, shuffleArray, normaliseClues } from "../utils/utils";
import axios from "axios";
import {
  setCardsLS,
  setNextCard,
  getCardsInLS,
  setLatestVersionLS,
  latestVersionLS,
} from "../utils/localStorage";

const gameInit = new Game();
const cardsInit = [...cards];

const queryString = window.location.search;

export const GameContext = createContext(null);
export const CardsContext = createContext(null);
export const InstallPromptContext = createContext(null);

const GlobalState = ({ children }) => {
  const [game, setGame] = useState(gameInit);
  const [cards, setCards] = useState([]);
  const [installPrompt, setInstallPrompt] = useState(null);
  const [cardsError, setCardsError] = useState(false);

  const getAndSetCards = (latestVersion = null) => {
    axios
      .get("https://halfaminute.co.za/cards.json", {
        timeout: getCardsInLS() ? 3000 : 2 * 60 * 1000,
      })
      .then((res) => {
        const cardData = res.data.map((cardObj) => {
          const normaliseCluesData = normaliseClues(cardObj.clues);
          const clues = normaliseCluesData
            .split("//")
            .map((clue) => clue.trim());
          return {
            id: cardObj.id,
            clues,
          };
        });
        setLSCards(cardData, latestVersion);
        // localStorage.setItem("game", JSON.stringify(game));
      })
      .catch(() => {
        if (getCardsInLS()) {
          setCards(getCardsInLS());
        } else {
          setCardsError(true);
          setCards(null);
        }
      });
  };

  const setLSCards = (newCards, version) => {
    const newCardsLS = shuffleArray(newCards);
    if (!getCardsInLS()) setCards(newCards);
    setCardsLS(newCards);
    setLatestVersionLS(version);
  };

  const cueNextCard = () => {
    setNextCard();
    setCards(getCardsInLS());
  };

  useEffect(() => {
    if (getCardsInLS()) {
      setCards(getCardsInLS());
    } else getAndSetCards(latestVersionLS);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(queryString);
    const isFromPWA = params.get("from") === "pwa";
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      if (!isFromPWA) {
        setInstallPrompt(e);
      }
    });
  }, []);

  return (
    <CardsContext.Provider value={[cards, cueNextCard]}>
      <GameContext.Provider value={[game, setGame]}>
        <InstallPromptContext.Provider value={installPrompt}>
          {children}
        </InstallPromptContext.Provider>
      </GameContext.Provider>
    </CardsContext.Provider>
  );
};

export default GlobalState;
