export const randomNumber = (floor = 0, ceil = 1) =>
  Math.floor(Math.random() * (ceil + 1)) + floor;

export const doNothingVariants = {
  init: {},
  anim: {},
  exit: { opacity: 0, transition: { duration: 0.7 } },
};

export const btnVariants = (delay = 0) => {
  const animationPropsX = [...Array(randomNumber(6, 10)).keys()].map((val) =>
    randomNumber(-3, 4)
  );
  const animationPropsY = animationPropsX.map((val) => randomNumber(-3, 4));

  return {
    init: { scale: 1 },
    anim: {
      scale: 1,
      x: animationPropsX,
      y: animationPropsY,
      transition: {
        type: "ease",
        repeat: Infinity,
        repeatType: "reverse",
        duration: 2.4 * animationPropsX.length,
        delay,
      },
    },
    exit: { scale: 0, transition: { duration: 0.5 } },
  };
};
