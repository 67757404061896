import React, { useContext, useRef, useState } from "react";
import { GameContext } from "../context/GameContext";
import { playerNameMaxLength } from "../utils/GlobalVariables";
import {
  Player,
  titleCase,
  shuffleAndSegmentPlayers,
  disabledStyles,
  validEntries,
} from "../utils/utils";
import { v4 as uuidv4 } from "uuid";
import LengthError from "./LengthError";
import AddedPlayerCard from "./AddedPlayerCard";

const RandomPlayers = ({ setSortedTeams, refreshPage }) => {
  const [game] = useContext(GameContext);
  const [randomPlayers, setRandomPlayers] = useState([]);
  const [currentName, setCurrentName] = useState("");

  const [nameError, setNameError] = useState(false);
  const textAreaRef = useRef();

  const enoughPlayers = () => {
    return randomPlayers.length / game.teams.length >= 2 ? true : false;
  };

  const randomiseBtnStyles = enoughPlayers()
    ? { fontSize: 12, marginTop: 10 }
    : { ...disabledStyles, fontSize: 12, marginTop: 10 };

  const handlePlayerNameEntry = (e) => {
    if (e.key === "Enter") {
      handleTextAreaInput(currentName + ",");
    } else if (validEntries.includes(e.key.toLowerCase())) {
      handleTextAreaInput(currentName + e.key);
    } else if (e.key === "Backspace") {
      handleTextAreaInput(currentName.slice(0, currentName.length - 1));
    } else {
    }
  };

  const handleTextAreaInput = (entry) => {
    // xxx - ADD MAX WIDTH
    setNameError(false);

    const nothingEntered = !currentName.trimLeft();
    if (nothingEntered && entry === "Enter") {
      setNameError(true);
      currentName !== "" && setCurrentName("");
      return;
    }

    const separator = ",";

    const namesInput = entry === "Enter" ? entry + "," : entry;
    const lastCharComma = namesInput[namesInput.length - 1] === separator;

    const name = namesInput.slice(0, namesInput.length - 1).trimLeft();

    if (
      lastCharComma &&
      name.length >= 2 &&
      name.length <= playerNameMaxLength
    ) {
      setRandomPlayers((prevPlayers) => [
        ...prevPlayers,
        new Player(titleCase(name.trim()), uuidv4()),
      ]);
      setCurrentName("");
    } else if (lastCharComma && !name) {
      !currentName && setCurrentName("");
    } else if (
      lastCharComma &&
      (name.length < 2 || name.length > playerNameMaxLength)
    ) {
      setCurrentName(name.slice(0, name.length));
      setNameError(true);
    } else if (name.length >= playerNameMaxLength + 1) {
      setCurrentName(name.slice(0, playerNameMaxLength + 1));
      setNameError(true);
    } else if (name.includes(separator)) {
      const separatorIndex = name.indexOf(separator);
      setCurrentName(name.slice(0, separatorIndex));
    } else {
      setCurrentName(titleCase(entry.trimLeft()));
    }
  };

  const removePlayer = (id) => {
    setRandomPlayers((prevPlayers) =>
      prevPlayers.filter((playerObj) => playerObj.id !== id)
    );
  };

  const randomisePlayers = () => {
    const fullyProcessedTeams = shuffleAndSegmentPlayers(
      randomPlayers,
      game.teams
    );
    setSortedTeams(fullyProcessedTeams);
    refreshPage();
  };

  const [val, setval] = useState(0);

  return (
    <div>
      <div className="random-teams-container">
        <div className="player-names-container">
          {randomPlayers.map((playerObj, index) => (
            <div key={playerObj.id + "rnadomdfdm"}>
              <AddedPlayerCard
                player={playerObj}
                onClickHandler={() => removePlayer(playerObj.id)}
              />
            </div>
          ))}
        </div>
        <label className="random-input-label">Players' Names</label>
        <input
          placeholder="enter player names"
          onInput={(e) =>
            game.browserSupported && handleTextAreaInput(e.target.value)
          }
          onKeyUp={(e) =>
            game.browserSupported &&
            e.key === "Enter" &&
            handleTextAreaInput(e.target.value + ",")
          }
          onKeyDown={(e) => !game.browserSupported && handlePlayerNameEntry(e)}
          value={currentName}
          // ref={textAreaRef}
          name="random-teams"
          className="random-teams-input"
        />
        {nameError && <LengthError />}
        <h4 className="comma-separate-msg">
          Press enter or comma separate each team member name. Click name card
          to remove it.
        </h4>
      </div>
      <button
        style={randomiseBtnStyles}
        onClick={randomisePlayers}
        className="quick-start-initiate-btn"
      >
        Make Random Teams
      </button>
    </div>
  );
};

export default RandomPlayers;
