// GAME VARIABLES
export const useTimer = "useTimer";
export const useDice = "useDice";
export const keepScore = "keepScore";

// MODES
export const quickStartMode = "quickStartMode";
export const teamsMode = "teamsMode";

// TEAM MODES
export const justTeams = "just teams";
export const randomMembers = "random members";
export const manualTeams = "manual teams";

// VARS
export const playerNameMaxLength = 10;
export const teamNameMaxLength = 13;
export const minNameLength = 3;
export const gameName = "5 Clues (beta)";
export const winningScore = 40;

// USER USING PWA
const queryString = window.location.search;
const params = new URLSearchParams(queryString);
export const isFromPWA = params.get("from") === "pwa";

// GAME STATES
export const waiting = "waiting";
export const running = "running";
export const gameDone = "gameDone";
export const diceStage = "diceStage";
export const setUp = "setUp";

// CARD ROTATION VARIABLES
export const width = window.innerWidth;
export const height = window.innerHeight;
export const rotateCondition =
  width < height && width > 300 && width < 900 && height < 1050;

// MODE OPTIONS INIT
export const modeOptionsInit = (selected) => {
  const options = [
    {
      mode: justTeams,
      description:
        "you will handle member sorting and player rotation for each team.",
      isSelected: true,
    },
    {
      mode: randomMembers,
      description:
        "you add a list of players and we'll randomly divide you up into teams.",
      isSelected: false,
    },
    {
      mode: manualTeams,
      description: "you enter members for each team manually.",
      isSelected: false,
    },
  ];
  options.forEach((option) => {
    if (option.mode === selected) {
      option.isSelected = true;
    } else {
      option.isSelected = false;
    }
  });
  return options;
};

export const initTeamNames = {
  team1Name: "",
  team2Name: "",
  team3Name: "",
  team4Name: "",
};

// NO WINNERS PLACEHOLDER
export const noWinnersPlaceholder = {
  winningTeams: [],
  teamsWithChance: [],
  losingTeams: [],
};

export const decryptKeys = {
  V: "2",
  t: "c",
  "]": "X",
  "'": "e",
  1: "O",
  b: "'",
  k: "F",
  "@": "{",
  W: "[",
  "|": "o",
  "?": "w",
  X: "g",
  v: "<",
  9: "V",
  f: "t",
  "\\": "=",
  n: "A",
  p: "K",
  w: "b",
  $: ".",
  "^": "(",
  q: "a",
  "+": "r",
  5: "Y",
  A: "^",
  ".": "8",
  ">": "P",
  u: "d",
  "`": "]",
  C: "#",
  " ": "~",
  E: "H",
  N: "L",
  "=": "D",
  h: "6",
  "<": ";",
  6: "S",
  ",": "0",
  O: ":",
  P: "J",
  "(": "|",
  "!": "`",
  4: "m",
  K: "1",
  3: "\\",
  Z: "v",
  l: "U",
  e: "f",
  M: "M",
  d: "T",
  '"': "*",
  R: "z",
  B: "y",
  8: ")",
  T: "Z",
  "/": "%",
  0: "/",
  j: "?",
  o: "E",
  y: "7",
  "#": "j",
  "{": "B",
  "-": "k",
  F: "Q",
  I: "I",
  z: "&",
  D: "4",
  g: "$",
  Q: "s",
  "%": "G",
  c: "x",
  L: "@",
  Y: ">",
  ")": "N",
  r: "+",
  U: "q",
  2: '"',
  G: "u",
  J: "-",
  "*": "}",
  m: "9",
  s: "C",
  a: ",",
  "}": "p",
  S: "5",
  ";": "R",
  _: "i",
  x: "!",
  "&": "W",
  7: "_",
  ":": "h",
  "~": "l",
  i: "n",
  "[": " ",
  H: "3",
};
