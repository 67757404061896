import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { GameContext } from "../context/GameContext";
import {
  titleCase,
  Player,
  disabledStyles,
  validEntries,
} from "../utils/utils";
import { playerNameMaxLength } from "../utils/GlobalVariables";
import { v4 as uuidv4 } from "uuid";
import useForceUpdate from "../hooks/useForceUpdate";
import LengthError from "./LengthError";
import AddedPlayerCard from "./AddedPlayerCard";

const ManualPlayers = ({ setSortedTeams }) => {
  const [game, setGame] = useContext(GameContext);
  const [playersAdded, setPlayersAdded] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [currentName, setCurrentName] = useState(game.teams.map((_) => ""));

  const playerNamesInit = () => {
    let init = [];
    game.teams.forEach((team) => {
      init.push([]);
    });
    return init;
  };

  const [playerNames, setPlayerNames] = useState(playerNamesInit());
  const forceUpdate = useForceUpdate();

  const handleOnKeyPress = (e, index) => {
    if (e.key === "Enter") {
      handleAddMember(currentName[index] + ",", index);
    } else if (validEntries.includes(e.key.toLowerCase())) {
      handleAddMember(currentName[index] + e.key, index);
    } else if (e.key === "Backspace") {
      handleAddMember(
        currentName[index].slice(0, currentName[index].length - 1),
        index
      );
    } else {
    }
  };

  const handleSetCurrentName = (index, val) =>
    setCurrentName((prev) =>
      prev.map((prevItem, i) => (i === index ? val : prevItem))
    );

  const handleAddMember = (entry, index) => {
    setNameError({ error: false, index });

    const nothingEntered = !currentName[index].trimLeft();
    if (nothingEntered && entry === "Enter") {
      setNameError({ error: true, index });
      currentName[index] !== "" && handleSetCurrentName(index, index, "");
      return;
    }

    const separator = ",";
    const namesInput = entry === "Enter" ? entry + "," : entry;
    const lastCharComma = namesInput[namesInput.length - 1] === separator;
    const name = namesInput.slice(0, namesInput.length - 1).trimLeft();

    if (
      lastCharComma &&
      name.length >= 2 &&
      name.length <= playerNameMaxLength
    ) {
      setPlayerNames((prevPlayers) => {
        prevPlayers[index] = [
          ...prevPlayers[index],
          new Player(titleCase(name.trim()), uuidv4()),
        ];
        return prevPlayers;
      });
      handleSetCurrentName(index, "");
    } else if (lastCharComma && !name) {
      !currentName[index] && handleSetCurrentName(index, "");
    } else if (
      lastCharComma &&
      (name.length < 2 || name.length > playerNameMaxLength)
    ) {
      handleSetCurrentName(index, name.slice(0, name.length));
      setNameError({ error: true, index });
    } else if (name.length >= playerNameMaxLength + 1) {
      handleSetCurrentName(index, name.slice(0, playerNameMaxLength + 1));
      setNameError({ error: true, index });
    } else if (name.includes(separator)) {
      const separatorIndex = name.indexOf(separator);
      handleSetCurrentName(index, name.slice(0, separatorIndex));
    } else {
      handleSetCurrentName(index, titleCase(entry.trimLeft()));
    }
    // forceUpdate();
  };

  const removePlayer = (id, index) => {
    setPlayerNames((prevPlayers) => {
      prevPlayers[index] = prevPlayers[index].filter((playerObj) => {
        return playerObj.id !== id;
      });
      return prevPlayers;
    });
    forceUpdate();
  };

  const enoughPlayers = () => {
    let moreThan2Players = playerNames.map((teamObj) => teamObj.length >= 2);
    return !moreThan2Players.includes(false);
  };

  const submitBtnStyles = enoughPlayers()
    ? { fontSize: 12 }
    : { ...disabledStyles, fontSize: 12 };

  return (
    <div className="teams-input-wrapper">
      {game.teams.map((team, teamIndex) => (
        <div key={team.id} className="manual-team-input-container">
          <div key={`teams${teamIndex}`} className="team-name-container">
            <div className="team-input-text-container">
              <label className="manual-teams-input-label">{team.name}</label>
              <input
                style={{ background: team.colour }}
                onInput={(e) =>
                  game.browserSupported &&
                  handleAddMember(e.target.value, teamIndex)
                }
                onKeyUp={(e) =>
                  game.browserSupported &&
                  e.key === "Enter" &&
                  handleAddMember(e.target.value + ",", teamIndex)
                }
                onKeyDown={(e) =>
                  !game.browserSupported && handleOnKeyPress(e, teamIndex)
                }
                value={currentName[teamIndex]}
                className="team-name-input"
                placeholder={`type player name`}
              />
            </div>
          </div>
          {nameError.error && nameError.index === teamIndex && <LengthError />}

          <div className="player-names-container">
            {playerNames.length > 0 &&
              playerNames[teamIndex].map((playerObj, index) => (
                <AddedPlayerCard
                  key={"manPlay" + playerObj.id}
                  player={playerObj}
                  colour={team.colour}
                  onClickHandler={() => removePlayer(playerObj.id, teamIndex)}
                />
              ))}
          </div>
        </div>
      ))}
      <h4 className="comma-separate-msg">
        Press enter or comma separate each team member name. Click name card to
        remove it.
      </h4>
      {/* {enoughPlayers() && ( */}
      <button
        style={submitBtnStyles}
        onClick={() => setSortedTeams(playerNames)}
        className="quick-start-initiate-btn"
      >
        Add Team Members
      </button>
    </div>
  );
};

export default ManualPlayers;
