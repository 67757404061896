import React, { useContext, useState, useEffect } from "react";
import { CardsContext, GameContext } from "../context/GameContext";
import {
  diceStage,
  quickStartMode,
  teamsMode,
  waiting,
} from "../utils/GlobalVariables";
import GameLogo from "./GameLogo";

const PostGameScreen = ({ correctGuessIndexes, pageRefresh }) => {
  const [game, setGame] = useContext(GameContext);
  const [cards, cueNextCard] = useContext(CardsContext);
  const [indexesOfCorrectGuesses, setIndexesOfCorrectGuesses] =
    useState(correctGuessIndexes);

  const card = cards[cards.length - 1][0];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleCorrectGuesses = (index) => {
    indexesOfCorrectGuesses.includes(index)
      ? setIndexesOfCorrectGuesses((prev) =>
          prev.filter((item) => item !== index)
        )
      : setIndexesOfCorrectGuesses([...indexesOfCorrectGuesses, index]);
  };

  const cluesStyles = (index) => {
    if (indexesOfCorrectGuesses.includes(index)) {
      return {
        color: "#eee",
      };
    }
    return { color: "#88888877" };
  };

  const checkOrCross = (index) => {
    if (indexesOfCorrectGuesses.includes(index)) {
      return {
        text: "✔",
        style: { color: "#77ff77" },
      };
    }
    return {
      text: "X",
      style: { color: "#ff5555" },
    };
  };

  const handleGameDone = () => {
    setGame((prevGame) => {
      prevGame.state = prevGame.useDice ? diceStage : waiting;
      if (prevGame.mode === teamsMode && prevGame.keepScore) {
        const diceVal = prevGame.diceValue ? prevGame.diceValue : 0;
        const oldScore = prevGame.order[0].score;
        const newScore =
          indexesOfCorrectGuesses.length <= diceVal
            ? 0
            : indexesOfCorrectGuesses.length - diceVal;
        prevGame.order[0].score = oldScore + newScore;
        prevGame.order[0].roundsPlayed += 1;
      }
      if (prevGame.useTeamMembers && prevGame.order[0].members.length > 1) {
        const currentPlayer = prevGame.order[0].members.splice(0, 1);
        prevGame.order[0].members = [
          ...prevGame.order[0].members,
          ...currentPlayer,
        ];
      }
      const currentTeam = prevGame.order.splice(0, 1);
      prevGame.order = [...prevGame.order, ...currentTeam];
      prevGame.diceValue = null;
      return prevGame;
    });
    pageRefresh();
  };

  return (
    <div className="active-game-wrapper">
      {game.useDice && typeof game.diceValue === "number" && (
        <div className="dice-graphic-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            className="dice-graphic-image"
            height="36"
            viewBox="0 0 45 36"
          >
            <path
              id="Icon_awesome-dice"
              data-name="Icon awesome-dice"
              d="M41.625,13.5H33.276a5.7,5.7,0,0,1-1.2,6.28L22.5,29.361v3.264A3.375,3.375,0,0,0,25.875,36h15.75A3.375,3.375,0,0,0,45,32.625V16.875A3.375,3.375,0,0,0,41.625,13.5ZM33.75,26.438a1.688,1.688,0,1,1,1.688-1.687A1.688,1.688,0,0,1,33.75,26.438ZM30.49,13.31,18.19,1.01a3.45,3.45,0,0,0-4.879,0L1.01,13.31a3.45,3.45,0,0,0,0,4.879l12.3,12.3a3.45,3.45,0,0,0,4.879,0l12.3-12.3a3.451,3.451,0,0,0,0-4.88ZM6.75,17.438A1.688,1.688,0,1,1,8.438,15.75,1.688,1.688,0,0,1,6.75,17.438Zm9,9a1.688,1.688,0,1,1,1.688-1.687A1.688,1.688,0,0,1,15.75,26.438Zm0-9a1.688,1.688,0,1,1,1.688-1.687A1.688,1.688,0,0,1,15.75,17.438Zm0-9A1.688,1.688,0,1,1,17.438,6.75,1.688,1.688,0,0,1,15.75,8.438Zm9,9a1.688,1.688,0,1,1,1.688-1.687A1.688,1.688,0,0,1,24.75,17.438Z"
            />
          </svg>
          <h3 className="dice-graphic-text">{game.diceValue}</h3>
        </div>
      )}
      {/* {window.innerHeight > 604 && <GameLogo padding />} */}
      <div className="card-wrapper">
        <div className="card">
          <div className="confirm-guesses-container">
            <h1 className="confirm-guesses-score">
              {indexesOfCorrectGuesses.length} Correct.
            </h1>
            <h3
              style={{ padding: "0 5px" }}
              className="confirm-guesses-description"
            >
              Click to select the guess you got right.
            </h3>
          </div>
          <div className="card-deco-container">
            <div className="card-deco card-deco-right">
              <h4></h4>
            </div>
            <div className="card-deco card-deco-left"></div>
          </div>
          <div className="list-items">
            {card.clues.map((clue, index) => (
              <div
                key={"summer" + index}
                onClick={() => toggleCorrectGuesses(index)}
                className="correct-clue-container"
              >
                <h3
                  style={cluesStyles(index)}
                  className={
                    indexesOfCorrectGuesses.includes(index)
                      ? "post-page-card-item "
                      : "post-page-card-item"
                  }
                >
                  {clue}

                  {indexesOfCorrectGuesses.includes(index) ? (
                    <svg
                      className="check-or-cross"
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="26.846"
                      viewBox="0 0 36 26.846"
                    >
                      <path
                        id="Icon_awesome-check"
                        fill="#55ff55"
                        data-name="Icon awesome-check"
                        d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                        transform="translate(0 -4.577)"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="check-or-cross"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30.849"
                      height="30.849"
                      viewBox="0 0 30.849 30.849"
                    >
                      <path
                        id="Icon_metro-cross"
                        fill="#ff5555"
                        data-name="Icon metro-cross"
                        d="M33.138,26.711h0l-9.358-9.358,9.358-9.358h0a.966.966,0,0,0,0-1.363L28.717,2.21a.967.967,0,0,0-1.363,0h0L18,11.568,8.637,2.21h0a.966.966,0,0,0-1.363,0L2.852,6.631a.966.966,0,0,0,0,1.363h0l9.358,9.358L2.852,26.711h0a.966.966,0,0,0,0,1.363l4.421,4.421a.966.966,0,0,0,1.363,0h0L18,23.136l9.358,9.358h0a.966.966,0,0,0,1.363,0l4.421-4.421a.966.966,0,0,0,0-1.363Z"
                        transform="translate(-2.571 -1.928)"
                      />
                    </svg>
                  )}
                </h3>
              </div>
            ))}
          </div>
          <button onClick={handleGameDone} className="end-game-btn">
            {game.mode === quickStartMode ? "Next Card" : "Next Round"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostGameScreen;
