import React from "react";

const BackButton = ({ onClickHandler }) => {
  return (
    <div className="back-btn" onClick={() => onClickHandler()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          id="Icon_material-arrow_back"
          data-name="Icon material-arrow_back"
          d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z"
          transform="translate(-6 -6)"
        />
      </svg>
    </div>
  );
};

export default BackButton;
