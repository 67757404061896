import React, { useContext, useEffect, useRef, useState } from "react";
import { GameContext } from "../context/GameContext";
import { useGesture } from "react-use-gesture";
import { validEntries } from "../utils/utils";

const InputContainer = ({ team, handleTeamNames }) => {
  const [game, setGame] = useContext(GameContext);
  const inputRef = useRef();
  const [val, setVal] = useState(0);

  const keyDownHandler = (e) => {
    const currentTeam = game.teams.filter(
      (teamObj) => teamObj.id === team.id
    )[0];

    if (e.key === "Enter") return;
    else if (validEntries.includes(e.key.toLowerCase())) {
      handleTeamNames(currentTeam.name + e.key, team.id);

      setVal((prev) => prev + 10);
    } else if (e.key === "Backspace") {
      setVal((prev) => prev + 1);
      handleTeamNames(
        currentTeam.name.slice(0, currentTeam.name.length - 1),
        team.id
      );
    } else setVal((prev) => prev + 33);
    // Backspace
  };

  const inputHandler = (e) => {
    setVal(e.target.value);
    handleTeamNames(e.target.value, team.id);
  };

  return (
    <div
      // style={{ background: team.colour + "75" }}
      className="team-name-container"
    >
      <input
        ref={inputRef}
        style={{ background: team.colour }}
        value={team.name}
        onKeyDown={(e) => !game.browserSupported && keyDownHandler(e, team.id)}
        onInput={(e) => game.browserSupported && inputHandler(e, team.id)}
        className="team-name-input"
        placeholder={team.name}
      />
    </div>
  );
};

export default InputContainer;
