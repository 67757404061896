import React, { useContext, useState } from "react";
import { GameContext } from "../context/GameContext";
import {
  modeOptionsInit,
  justTeams,
  randomMembers,
  manualTeams,
} from "../utils/GlobalVariables";

const RadioBtns = ({ selectedRadio, setSelectedRadio }) => {
  const [game, setGame] = useContext(GameContext);
  const [modeOptions, setModeOptions] = useState(
    modeOptionsInit(selectedRadio)
  );

  const radioStyle = (isSelected, halfOpacity = false) => {
    return isSelected ? {} : { opacity: halfOpacity ? "0.66" : "0.32" };
  };

  const handleTeamsMode = (mode) => {
    const newModeOptions = modeOptions.map((option) => {
      if (option.mode === mode.mode) {
        option.isSelected = true;
        setSelectedRadio(mode.mode);
      } else {
        option.isSelected = false;
      }
      return option;
    });
    setModeOptions(newModeOptions);
  };

  return (
    <div className="radio-btn-wrapper">
      {modeOptions.map((modeOption, index) => (
        <div key={modeOption.mode + index + "rddfsfasw"}>
          <div
            key={`radios${index}`}
            className="radio-container"
            onClick={() => handleTeamsMode(modeOption)}
          >
            <h2
              style={radioStyle(modeOption.isSelected)}
              className="radio-title"
            >
              {modeOption.mode}
            </h2>
            <h3
              style={radioStyle(modeOption.isSelected, true)}
              className="radio-description"
            >
              {modeOption.description}
            </h3>

            {/* <div className="radio-btn-text-container">
              </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RadioBtns;
