import React, { useEffect, useContext } from "react";
import { InstallPromptContext } from "../context/GameContext";

const GameLogo = ({ padding }) => {
  const paddingClass = padding ? " logo-padding" : "";
  const installPrompt = useContext(InstallPromptContext);

  const handlePrompt = () => {
    if (installPrompt) {
      installPrompt.prompt();
    }
  };

  return (
    <div onClick={handlePrompt} className={"logo-container" + paddingClass}>
      <img
        src={process.env.PUBLIC_URL + "android-chrome-192x192.png"}
        alt="logo"
      />

      {/* <h2  className="logo-text">
        (beta)
      </h2> */}
    </div>
  );
};

export default GameLogo;
