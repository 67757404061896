import React, { useContext } from "react";
import { GameContext } from "../context/GameContext";
import {
  useTimer,
  quickStartMode,
  useDice,
  teamsMode,
  diceStage,
  waiting,
  isFromPWA,
} from "../utils/GlobalVariables";
import GameLogo from "../components/GameLogo";
import useForceUpdate from "../hooks/useForceUpdate";
import CheckBox from "../components/CheckBox";
import { redirectIfMust } from "../utils/utils";
import BackButton from "../components/BackButton";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { btnVariants } from "../utils/animationUtils";
import PageContainer from "../components/PageContainer";
import { timerSupportMsg } from "../utils/content";

const QuickStartPage = () => {
  const [game, setGame] = useContext(GameContext);
  const forceUpdate = useForceUpdate();

  const handleGameStart = () => {
    setGame((prev) => {
      //   prev.mode = quickStartMode;
      prev.state = prev.useDice ? diceStage : waiting;
      return prev;
    });
    forceUpdate();
  };

  const checkCircleStyle = (val) => (val ? {} : { opacity: "0.3" });

  const handleCheckCircle = (setting) => {
    setGame((prevGame) => {
      prevGame[setting] = !prevGame[setting];
      return prevGame;
    });
    forceUpdate();
  };

  const backBtnHandler = () => {
    setGame((prevGame) => {
      prevGame.mode = "";
      return prevGame;
    });
    forceUpdate();
  };

  const timerMsg = !game.browserSupported
    ? "This browser doesn't support using a timer."
    : timerSupportMsg;

  return (
    <PageContainer classTitle="quickstart-page">
      {redirectIfMust(game)}
      <GameLogo padding />
      {<BackButton onClickHandler={backBtnHandler} />}

      <h3 className="quickstart-heading">Quickstart Options</h3>

      <div className="quick-start-btns">
        <motion.button
          variants={btnVariants()}
          onClick={() => handleCheckCircle(useDice)}
          style={checkCircleStyle(game.useDice)}
          className="quick-start-btn quick-start-dice"
        >
          Use Dice
        </motion.button>

        <motion.button
          variants={btnVariants(0.3)}
          onClick={() => game.browserSupported && handleCheckCircle(useTimer)}
          style={checkCircleStyle(game.useTimer)}
          className="quick-start-btn quick-start-timer"
        >
          Use Timer
        </motion.button>
      </div>

      {(!game.browserSupported || game.useTimer) && (
        <h3 className="browser-support-msg">{timerMsg}</h3>
      )}

      <div className="quickstart-start-btn-container">
        <button onClick={handleGameStart} className="quick-start-initiate-btn">
          Start Game
        </button>
      </div>
    </PageContainer>
  );
};

export default QuickStartPage;
