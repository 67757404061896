import React from "react";
import {
  playerNameMaxLength,
  teamNameMaxLength,
} from "../utils/GlobalVariables";

const LengthError = ({ isTeam = false }) => {
  const errorText = isTeam
    ? `team name must be between 1 & ${teamNameMaxLength} characters`
    : `names must be between 2 and ${playerNameMaxLength} characters`;

  return (
    <div className="name-length-error">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="28.5"
        viewBox="0 0 33 28.5"
      >
        <path
          id="Icon_material-warning"
          data-name="Icon material-warning"
          d="M1.5,31.5h33L18,3Zm18-4.5h-3V24h3Zm0-6h-3V15h3Z"
          transform="translate(-1.5 -3)"
          fill="#ff1111"
        />
      </svg>
      <h6>{errorText}</h6>
    </div>
  );
};

export default LengthError;
